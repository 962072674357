@import 'mantine';
.section {
  position: relative;
  padding-top: rem(80);
  padding-bottom: rem(40);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    padding-top: rem(120);
  }
}

.grid {
  z-index: 1;
  color: var(--mantine-color-gray-0);
}

.gridCol {
  position: relative;
  height: fit-content !important;
}

.content {
  position: relative;
  margin: auto;
  background-image: linear-gradient(
      to right,
      transparent,
      rgb(8 13 29 / 60%) 80%
    ),
    linear-gradient(0deg, rgb(0 57 164 / 60%), rgb(0 57 164 / 60%)),
    url('/pages/home/home-scaling-up-data.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  border-radius: rem(8);
  padding: rem(16) rem(24) rem(40);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: rem(9999);
    padding: rem(70) rem(110);
  }
}

.resource {
  border-bottom: 1px solid rgba(255 255 255 / 20%);
  justify-content: space-between;
  padding-bottom: rem(16);

  &:not(:first-child) {
    padding-top: rem(16);
  }

  align-items: end;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    border-bottom: none;
    flex-direction: row-reverse;
    justify-content: start;
    padding-top: initial;
    padding-bottom: initial;
  }

  .resourceName {
    font-size: rem(26);
    margin: 0;
  }

  .resourceInfo {
    font-size: rem(14);
    color: var(--mantine-color-gray-6);
    margin: 0;
    text-transform: uppercase;
  }
}

.text {
  flex: 1 1 0px;
  padding-top: rem(32);
  text-align: center;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    text-align: start;
    padding-top: 0;
  }
}

.title {
  font-size: rem(24);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    font-size: rem(32);
  }
}

.resourcesList {
  flex: 1 1 0px;

  p {
    @include larger-than($mantine-breakpoint-desktop-sm) {
      margin: 0;
    }
  }
}

.titleWrapper {
  align-items: end;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    align-items: center;
  }
}

.lines {
  position: absolute;
  top: rem(-80);
  left: rem(0);
  z-index: 0;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    top: rem(-60);
    left: rem(-70);
    z-index: 2;
  }
}
